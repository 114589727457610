<template>
  <v-container>
    <v-row>
      <h1 class="text-center my-4 titulo">
        DEFINE TUS UMBRALES
      </h1>
      <v-expansion-panels
        multiple
        popout
      >
        <v-expansion-panel
          :key="dispositivo.codigo"
          v-for="(dispositivo, dispositivo_index) in dispositivos"
        >
          <v-expansion-panel-header>{{ dispositivo.nombre }} ({{ dispositivo.codigo }})</v-expansion-panel-header>
          <v-expansion-panel-content class="dispositivo__container">
            <v-container>
              <v-row
                justify="center"
                v-if="dispositivo.sensores"
              >
                <v-col
                  :key="`sensor-${sensor.codigo}`"
                  cols="12"
                  md="6"
                  v-for="(sensor, sensor_index) in dispositivo.sensores"
                >
                  <v-card
                    class="mx-auto"
                  >
                    <v-card-text>
                      <p class="display-2 text-center text--primary">
                        {{ sensor.nombre }} <br>
                        <span class="subtitle-1">({{ sensor.codigo }})</span>
                      </p>
                      <div class="text--lighten-4 subtitle-2">
                        {{ sensor.descripcion }}
                      </div>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions>
                      <v-container>
                        <v-form ref="form">
                          <v-row>
                            <v-col
                              cols="4"
                            >
                              <v-subheader>Umbral medio</v-subheader>
                            </v-col>
                            <v-col
                              cols="8"
                            >
                              <v-text-field
                                :suffix="sensor.unidad_medida.simbolo || ''"
                                v-model="sensor.umbral_medio"
                                class="text-right"
                                :rules="[rules.required]"
                                color="white"
                                full-width
                                outlined
                                single-line
                                type="number"
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                            >
                              <v-subheader>Umbral alto</v-subheader>
                            </v-col>
                            <v-col
                              cols="8"
                            >
                              <v-text-field
                                :suffix="sensor.unidad_medida.simbolo || ''"
                                v-model="sensor.umbral_alto"
                                class="text-right"
                                :rules="[rules.required]"
                                color="white"
                                full-width
                                outlined
                                single-line
                                type="number"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              class="d-flex justify-end"
                            >
                              <v-btn
                                @click="actualizarUmbrales(dispositivo_index, sensor_index)"
                                class="mx-2"
                                height="48px"
                                ref="botonActualizar"
                                :loading="sensor.botonLoading"
                              >
                                Actualizar umbrales
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
  import { required } from '../../../utils/rules'

  export default {
    name: 'Alarmas',
    data: () => {
      return {
        dispositivos: [],
        rules: {
          required,
        },
      }
    },
    async mounted () {
      const dispositivosResponse = await this.$http.get('/iot/dispositivos/')

      this.dispositivos = dispositivosResponse.data
      this.dispositivos.forEach(dispositivo => {
        this.dispositivos.sensores = dispositivo.sensores.map(sensor => {
          sensor.botonLoading = false
        })
      })
    },
    methods: {
      actualizarUmbrales (dispositivo_index, sensor_index) {
        const isValid = this.$refs.form[dispositivo_index].validate()
        if (!isValid) {
          return
        }
        const sensor_id = this.dispositivos[dispositivo_index].sensores[sensor_index].id
        const umbral_medio = this.dispositivos[dispositivo_index].sensores[sensor_index].umbral_medio
        const umbral_alto = this.dispositivos[dispositivo_index].sensores[sensor_index].umbral_alto
        this.dispositivos[dispositivo_index].sensores[sensor_index].botonLoading = true
        const payload = {
          umbral_medio,
          umbral_alto,
        }
        this.$http.patch(`iot/sensores/${sensor_id}/`, payload).then(() => {
          this.$toast.success('Actualizado correctamente.')
        }).finally(() => {
          this.dispositivos[dispositivo_index].sensores[sensor_index].botonLoading = false
        })
      },
    },
  }
</script>

<style scoped>
  .titulo {
    width: 100%;
  }

  .dispositivo__container {
    background: #272727;
  }
</style>
